let message = ''

export default {
    getMessage: function () {
        return message
    },
    setMessage: function (newMessage) {
        message = newMessage
        return true
    },
}
